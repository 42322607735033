import Api from '@/services/Api'

export default {
  get(id) {
    return Api().get('/api/payments/' + id)
  },
  create(params) {
    return Api().post('/api/payments', params)
  },

  list(params) {
    return Api().get('/api/payments', {
      params: params
    })
  },
  delete() {
    return Api().delete('/api/payments')
  }
}
